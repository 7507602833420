import model from './model';
import { CategoriesGalleryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoriesGalleryWidgetProps as Props } from './config/types';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/types'
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import { getCategoryUrl } from '../../utils';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    galleryWidget: $w(ComponentIds.GalleyWIdget),
  });

  const getWixCodePGItems = (categories: Category[]) => {
    const categoryCoverImages = categories.map((category: Category) => {
      const item: Item = {
        link: {
          url: getCategoryUrl(flowAPI, category.slug),
        },
        image: category.coverImage,
      };
      return item;
    });
    return commonImagesToWixCode(categoryCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.categories.length !== 0) {
      components.galleryWidget.items = getWixCodePGItems(data.categories);
    }
  };

  $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
