import { ProjectDetail } from '@wix/ambassador-portfolio-projects-v1-project/types';

export const CATEGORY_SLUG_PATH_INDEX = 0;
export const PROJECT_SLUG_PATH_INDEX = 1;

export const getBaseUrl = (flowAPI) => {
  return `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}`;
};

export const getPortfolioBaseUrl = (flowAPI) => {
  return `${getBaseUrl(flowAPI)}/${
    flowAPI.controllerConfig.wixCodeApi.location.prefix
  }`;
};

export const getCategoryUrl = (flowAPI, categorySlug) => {
  return `${getPortfolioBaseUrl(flowAPI)}/${categorySlug}`;
};

export const getProjectUrl = (flowAPI, categorySlug, projectSlug) => {
  return `${getCategoryUrl(flowAPI, categorySlug)}/${projectSlug}`;
};

export const getCollectionSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[
    CATEGORY_SLUG_PATH_INDEX
  ];
};

export const getProjectSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[
    PROJECT_SLUG_PATH_INDEX
  ];
};
export const getDetailSubtitle = (details: ProjectDetail[]) => {
  if (!Array.isArray(details)) {
    return;
  }
  let subtitle = '';
  details.forEach((item) => {
    const { key, text } = item;
    subtitle += `<div style="display:flex;"><div style="margin-right:10px">${key}:</div><div>${text}</div></div>`;
  });
  subtitle = getRichTextFormat(subtitle);
  return subtitle;
};
export const getRichTextFormat = (html) => {
  // util till fix from rich text side
  html = html.toString().replaceAll('<p', '<div');
  html = html.toString().replaceAll('</p', '</div');
  return html;
};
