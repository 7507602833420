import model from './model';
import {
  ProjectPageWidgetComponentIds as ComponentIds,
  ProjectPageWidgetStates as States,
} from './config/constants';
import { ProjectPageWidgetProps as Props } from './config/types';
import {
  queryProjects,
  getProjectPageData,
} from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { GetProjectPageDataResponse } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import { ProjectItem } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import {
  getComponentSettings,
  setComponentSettings,
} from '@wix/ambassador-app-settings-v1-settings/http';
import { Host, State } from '@wix/ambassador-app-settings-v1-settings/types';
import { getCollectionSlugFromUrl, getProjectSlugFromUrl } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer } = flowAPI.environment;
  let components: ReturnType<typeof getComponents>;
  let projectItems: ProjectItem[] = [];
  let projectPageData: GetProjectPageDataResponse = {};

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiStateBox),
    projectWidget1: $w(ComponentIds.ProjectWidget1),
    projectWidget2: $w(ComponentIds.ProjectWidget2),
    projectWidget3: $w(ComponentIds.ProjectWidget3),
  });

  const getFirstProject = async () => {
    return flowAPI.httpClient
      .request(
        // @ts-expect-error
        queryProjects({
          query: {
            filter: { hidden: { $eq: false } },
          },
        }),
      )
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return projects[0];
      });
  };

  const getProjectBySlug = async () => {
    const projectSlug = getProjectSlugFromUrl(flowAPI);
    const collectionSlug = getCollectionSlugFromUrl(flowAPI);
    const res = await flowAPI.httpClient
      // @ts-expect-error
      .request(getProjectPageData({ collectionSlug, projectSlug }));
    return res.data;
  };

  const initProjectPageData = async () => {
    if (isViewer) {
      projectPageData = await getProjectBySlug();
      if (projectPageData.project?.id) {
        projectItems = await getProjectItems(projectPageData.project.id);
        setProjectWidgetInnerProps();
      }
    }
  };

  const getProjectItems = async (projectId) => {
    const res = await flowAPI.httpClient
      // @ts-expect-error
      .request(listProjectItems({ projectId }));
    return res.data.projectItems || [];
  };

  const getProjectLayoutState = async () => {
    const { data } = await flowAPI.httpClient.request(
      // @ts-expect-error
      getComponentSettings({
        state: State.SAVED,
        componentId: flowAPI.controllerConfig.appParams.instanceId,
        host: Host.VIEWER,
      }),
    );
    return data.settings?.projectLayoutId;
  };
  const setProjectLayoutState = async (settings: any) => {
    const { data } = await flowAPI.httpClient.request(
      // @ts-expect-error
      setComponentSettings({
        componentId: flowAPI.controllerConfig.appParams.instanceId,
        host: Host.VIEWER,
        state: State.SAVED,
        settings,
      }),
    );
    return data.settings?.projectLayoutId;
  };

  const initMSBState = async () => {
    if (isViewer) {
      await components.multiStateBox.changeState($widget.props.data.projectTemplate);
    } else {
      const projectPageSelectedState = await getProjectLayoutState();
      if (projectPageSelectedState) {
        await components.multiStateBox.changeState(projectPageSelectedState);
        $widget.props.data.projectTemplate = projectPageSelectedState;
      }
    }
  };

  const setProjectWidgetInnerProps = () => {
    const projectWidgetData = {
      project: projectPageData.project,
      items: projectItems,
      nextProjectSlug: projectPageData.nextProject?.slug,
      prevProjectSlug: projectPageData.previousProject?.slug,
    };
    switch ($widget.props.data.projectTemplate) {
      case States.STATE_1 as string:
        components.projectWidget1.data = projectWidgetData;
        break;
      case States.STATE_2 as string:
        components.projectWidget2.data = projectWidgetData;
        break;
      case States.STATE_3 as string:
        components.projectWidget3.data = projectWidgetData;
        break;
      default:
        break;
    }
  };

  const initProjectPage = async () => {
    await initMSBState();
    await initProjectPageData();
  };
  const onPropsChanged = async () => {
    const { projectTemplate } = $widget.props.data;
    if (projectTemplate) {
      await setProjectLayoutState({
        projectLayoutId: projectTemplate,
      });
      await components.multiStateBox.changeState(projectTemplate);
      await initProjectPageData();
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      // @ts-expect-error
      const isFirstTime = !flowAPI.controllerConfig?.livePreviewOptions?.compsIdsToReset;
      isFirstTime ? await initProjectPage() : await onPropsChanged();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
