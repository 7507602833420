import model from './model';
import { CategoryPageWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryPageWidgetProps as Props } from './config/types';
import { queryProjectsWithCollectionInfo } from '@wix/ambassador-portfolio-projects-v1-project/http';
import {
  ProjectInCollection,
  SortOrder,
} from '@wix/ambassador-portfolio-projects-v1-project/types';
import { queryCategory } from '@wix/ambassador-portfolio-categories-v1-category/http';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/build/es/types';
import { getCollectionSlugFromUrl, getPortfolioBaseUrl } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer } = flowAPI.environment;

  let components: ReturnType<typeof getComponents>;
  let currCategory: Category = {};
  let projectsInCategory: ProjectInCollection[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    categoryWidget: $w(ComponentIds.CategoryWidget),
  });

  const getFirstCategory = () => {
    return flowAPI.httpClient
      .request(
        // @ts-expect-error
        queryCategory({
          query: {
            filter: { hidden: { $eq: false } },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { categories = [] },
        } = res;
        return categories[0];
      });
  };

  const getCategoryBySlug = () => {
    const categorySlug = getCollectionSlugFromUrl(flowAPI);
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(queryCategory({ query: { filter: { slug: categorySlug } } }))
        .then((res) => {
          const {
            data: { categories = [] },
          } = res;

          return categories[0];
        })
    );
  };

  const getCategory = async () => {
    return getCategoryBySlug();
  };

  const getProjects = async (collectionId) => {
    return flowAPI.httpClient
      .request(
        // @ts-expect-error
        queryProjectsWithCollectionInfo({
          query: {
            filter: {
              collectionId: { $eq: collectionId },
              hidden: { $eq: false },
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return projects;
      });
  };

  const initCategoryPage = async () => {
    if (isViewer) {
      currCategory = (await getCategory()) as Category;
      if (currCategory?.id) {
        projectsInCategory = await getProjects(currCategory.id);
        components.categoryWidget.data = {
          category: currCategory,
          projects: projectsInCategory.map((p) => p.project),
        };

        components.navigationWidget.data = {
          hideNext: true,
        };
      }
    }
  };

  const handleInteractions = () => {
    // components.navigationWidget.onNextClicked(() => {
    //   console.log('next clicked ! category page widget');
    // });
    if (isViewer) {
      components.navigationWidget.onBackClicked(() => {
        // @ts-expect-error
        flowAPI.controllerConfig.wixCodeApi.location.to(
          getPortfolioBaseUrl(flowAPI),
        );
        console.log('back clicked ! category page widget');
      });
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      handleInteractions();
      await initCategoryPage();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
