import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';
import { getRichTextFormat, getDetailSubtitle } from '../../utils';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import * as imageSdk from '@wix/image-client-api/dist/imageClientSDK';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer } = flowAPI.environment;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    subtitle: $w(ComponentIds.SubTitle),
    description: $w(ComponentIds.Description),
    image: $w(ComponentIds.HeroImage),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    const { title, subtitle, description, heroImage } = data;

    if (isViewer) {
      components.title.text = title;
      components.subtitle.richText = subtitle
        ? getDetailSubtitle(subtitle)
        : '';
      components.description.richText = description
        ? getRichTextFormat(description)
        : '';

      if (heroImage?.imageInfo?.filename) {
        components.image.src =
          imageSdk.getScaleToFillImageURL(
            heroImage.imageInfo.filename,
            heroImage.imageInfo.width,
            heroImage.imageInfo.height,
            heroImage.imageInfo.width,
            heroImage.imageInfo.height
          ) +
          '?token=' +
          heroImage.token;
      }
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    initByProps(nextProps);
  });

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
